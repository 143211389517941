import React from 'react';
import HighlightGuide from '../../../../components/guides/HighlightComponent';
import Footer from '../../../../components/FooterComponent';

import { HaiVanPass } from '../../../../guides/countries/vietnam/hai-van-pass';

function HaiVanPassPage(props) {
    return (
        <div>
            <HighlightGuide highlight={ HaiVanPass } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default HaiVanPassPage;
