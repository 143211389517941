import React from 'react';
import CaptionImage from '../../../components/elements/CaptionImage';

export const HaiVanPass = {
    "name": "Hai Van Pass",
    "link": "hai-van-pass",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50379693863_341a4c7c72_t.jpg",
    "image": "https://live.staticflickr.com/65535/50379693863_75b9279a19_o.jpg",
    "caption": "A Deserted Ribbon Of Perfection",

    "brief": "Ever since 2008, when it aired on \"Top Gear\", the Hai Van Pass has become a staple on motorbike rides, not only in Southeast Asia, but all around the world. Jeremy Clarkson on his program described it as \"a deserted ribbon of perfection\", and nothing else better describes this perfect 25km strip of road connecting Da Nang and Hue, considered by the program to be \"one of the best coastal roads in the world\".",

    "description": <div>
            Ever since 2008, when it aired on <i>"Top Gear"</i>, the Hai Van Pass has become a staple on motorbike rides, not only in Southeast Asia, but all around the world.
            Jeremy Clarkson on his program described it as <i>"a deserted ribbon of perfection"</i>, and nothing else better describes this perfect 25km strip of road connecting <b>Da Nang and Hue</b>,
            considered by the program to be <i>"one of the best coastal roads in the world"</i>.
            <br/><br/>
            Once you come and experience this little gem, you will know why. During much of history, the Hai Van Pass served as the border between the <b>Champa</b> and <b>Dai Viet Kingdoms</b>,
            and today it still somewhat divides two different sides of Vietnam, the north and the south.
            <br/><br/>
            <CaptionImage image="https://live.staticflickr.com/65535/50504293208_83f98b4b46_o.jpg" name="Hai Van Pass" caption="Curvy roads of Hai Van Pass" />
            <br/>
            For adventure lovers, what you will find here is a thrill ride, filled with curves and sudden corners that ascends you 500m above sea level on coastal cliffs,
            that will offer you at every opportunity amazing, <b>breathtaking views</b> of this coastal scenery. The little village of Lang Co, located at one of the extremities of the pass, is a great example of the blend on traditional and modern Vietnam.
            <br/><br/>
            All this perhaps, is what makes the Hai Van Pass so memorable. It shows both sides of Vietnam, north and south, mixed with incredible scenery and a thrill ride, and all this in just 25km road that can be done in a day.
        </div>,

    "howToGet": <p>
            Hai Van Pass stands between <b>Da Nang</b> and <b>Hue</b>, and is the route you <b>must take</b> when going from one city to the other if going by motorbike.
            <br/><br/>
            Please note, that since the construction of the Hai Van Tunnel, almost all "4-wheel" traffic is forced to go through the tunnel, which means motorbikes are the only way to see the pass.
        </p>,

    "whatToDo": {
        "description": <div>
                There are not many secrets about the Hai Van Pass, the best part of it is driving on the actual road and marvel at all the spectacular scenery.
                It should be obvious that the best way to enjoy this gem is on a motorbike, and ever since the construction of the Hai Van tunnel, much of the transit is forced to use the tunnel,
                leaving the pass only for motorbikes and the occasional oil tanker which is not allowed inside.
                <br/><br/>
                If you are not a confident driver, or don't have much experience riding motorbikes, you can opt for an <b>Easy Rider</b> alternative. You will find many, many options on either Da Nang, Hue or even Hoi An,
                and it basically consists in someone driving the motorbike, while you go on the back seat and enjoy the scenery.
                <br/><br/>
                <CaptionImage image="https://live.staticflickr.com/65535/50505007136_49a61a1b4f_o.jpg" name="Hai Van Pass" caption="The mountain range of the Hai Van Pass" />
                <br/>
                Besides the obvious points of interest you will find along the way, there are some spots a little bit off the main path that may deserve a visit, we will list some here.
                We took the route from Da Nang to Hue, and will be presenting the spots in the order they appeared to us following that direction.
            </div>,

        "activities": [
            {
                "name": "Lang Co Beach",
                "description": <p>
                        Sitting at the exit (or entrance, depending where you start) of the Hai Van Pass, the Lang Co Village is a great example of a charming, traditional village of Vietnam.
                        The village offers a great scenery from afar, and inside you will find some of the best seafood dishes at reasonable prices.
                        <br/><br/>
                        But without a doubt, the main attraction of the village is the <b>Lang Co Beach</b>. It's a beautiful, calming spot for you to relax a bit after the pass.
                        <br/><br/>
                        If you are enjoying the place, spending a night in Lang Co may not be a bad idea.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50504293158_f6ca7cd4b0_o.jpg"
            },
            {
                "name": "Elephant Springs",
                "description": <p>
                (photo from <a href="https://localvietnam.com/blog/the-elephant-springs-suoi-voi-between-hue-and-da-nang/" target="_blank" rel="noopener noreferrer">localvietnam.com</a>)
                        <br/><br/>
                        On your way to Hue, you will have a chance to make a slight detour to the <b>Elephant Springs</b>. Although, the detour is of almost 15km, what you can find more than justifies it.
                        <br/><br/>
                        Here, the river flows throughout a series of natural pools, where you can go for a swim in its crystal clear waters. The entrance fee to this place costs 15.000VND per person plus 2.000VND per motorbike,
                        and once you are inside, you will have to pay an additional, <b>bargainable</b> fee, to use the poolside huts before entering the pools.
                        <br/><br/>
                        The place also has some stands selling foods and drinks, although the prices here are way more expensive than they should.
                    </p>,
                "image": "https://localvietnam.com/wp-content/uploads/2019/08/elephant-springs-hue-1024x569.jpg"
            }
        ]
    }
}
